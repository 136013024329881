/**@jsx jsx */
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { jsx } from "theme-ui";

const Profile = () => {
    const data = useStaticQuery(graphql`
		query {
			file(relativePath: { eq: "henry.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2773, maxHeight: 3424) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);
	return (
		<Img
			sx={{
				borderRadius: `100%`,
				width: ["100%", "15%"],
				height: `auto !important`,
			}}
			fluid={data.file.childImageSharp.fluid}
			alt="Profile Pic"
		/>
	);
};

export default Profile;