import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Timeline from "../components/timeline";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Work Experience`}</h2>
    <Timeline mdxType="Timeline">
      <div className="container">
   <div className="timeline-block timeline-block-right">
      <div className="marker"></div>
      <div className="timeline-content">
        <h3>Senior Marketing Web Developer</h3>
        <span>Newfold Digital (formerly Dreamscape Networks Inc.)</span>
        <span className="date">November 2018 - Present</span>
        <h6>Roles and Responsibilities:</h6>
        <ul>
          <li>Writes, reviews, and debugs testable code using the best softwaredevelopment practices</li>
          <li>Works alongside Marketing’s Web Developers in maintaining an ‘agile’ software development process</li>
          <li>Maintains, expands, and scales our content hub and other Marketing managed website pages</li>
          <li>Creates timeline for web development tasks for marketing campaigns - with approval from Content Marketing Manager</li>
          <li>Mentors Web Developer to acquire new or improve the existing web development knowledge and skills that would help in efficient and effective work performance</li>
          <li>Integration with Salesforce Marketing Cloud(SFMC), RESTful API Integration with third-party software like Blinger and Zendesk, Hubspot, Adestra, and Zapier</li>
          <li>Project communication and coordination from System Admin, Designers, Content Writers, and Managers in the Marketing Team </li>
        </ul>
      </div>
   </div>
        <div className="timeline-block timeline-block-left">
  <div className="marker"></div>
  <div className="timeline-content left">
    <h3>Front End Web Developer</h3>
    <span>Bluefrog Contents and Support Inc.</span>
    <span className="date">March 2017 - Sept 2018</span>
    <h6>Roles and Responsibilities:</h6>
    <ul>
      <li>
        Develop and maintain Sportsbook websites (website for viewing of
        livesports, livegames and online betting of the casino games and other
        sports games)
      </li>
      <li>
        Develop and maintain the company's online casino games specifically
        Baccarat, Poker, Dragon Tiger, Sicbo, Pai Gow and Lobby
      </li>
      <li>Creating mini games web applications for side betting</li>
    </ul>
  </div>
        </div>
        <div className="timeline-block timeline-block-right">
  <div className="marker"></div>
  <div className="timeline-content">
    <h3>Web Programmer</h3>
    <span>Clear Export Industries Inc.</span>
    <span className="date">June 2016 - February 2017</span>
    <h6>Roles and Responsibilities:</h6>
    <ul>
      <li>
        Develop and maintain the company's system including Sales, Inventory,
        Production and Control and Human Resource modules
      </li>
      <li>
        Design company website layout and prototype using Adobe XD and PSD
      </li>
    </ul>
  </div>
        </div>
        <div className="timeline-block timeline-block-left">
  <div className="marker"></div>
  <div className="timeline-content left">
    <h3>Front End Web Developer</h3>
    <span>Brainhub Inc.</span>
    <span className="date">November 2015 - April 2016</span>
    <h6>Roles and Responsibilities:</h6>
    <ul>
      <li>Developing and maintaining the company’s website using WordPress</li>
      <li>
        Using Agile methodology for project management we worked as a team with
        a Designer and a Project Manager
      </li>
      <li>
        Worked alongside with a back-end developer to integrate HTML templates
        of Django framework into an application
      </li>
    </ul>
  </div>
        </div>
   <div className="timeline-block timeline-block-right">
      <div className="marker"></div>
      <div className="timeline-content">
         <h3>Web Developer</h3>
         <span>June Spring Multimedia</span>
         <span className="date">May 2015 - October 2015</span>
         <h6>Roles and Responsibilities:</h6>
         <ul>
            <li>Work alongside Project Managers, Graphic Designers, SEO specialists using Agile Methodology</li>
            <li>Develop and maintain client's website using WordPress</li>
            <li>Develop and maintain an E-Commerce website using NETO CMS</li>
         </ul>
      </div>
   </div>
      </div>
    </Timeline>;

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      