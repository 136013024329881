/** @jsx jsx */
import { Box, Flex, Link, useColorMode, jsx } from "theme-ui"
import Icon from "/svg/gatsby_logo_white.svg";

const Footer = () => {
  const [colorMode, setColorMode] = useColorMode()
  const isDark = colorMode === `dark`
  const toggleColorMode = (e: any) => {
    setColorMode(isDark ? `light` : `dark`)
  }

  return (
    <Box as="footer" variant="footer">
      Made with <Link
          aria-label="Gatsby"
          href="https://www.gatsbyjs.com/">
          <Icon />
        </Link>
      <br />
    </Box>
  )
}

export default Footer