import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Profile from "../components/Profile";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      flexFlow: "column"
    }}>
  <Profile mdxType="Profile" />
    </div>
    <h1>{`Hi, I'm Henry Mirasol`}</h1>
    <p>{`Experienced and passionate web developer with 6 years of
experience in Web Development. Has a solid background in modern
web technologies including Single Page Applications (SPA), Static
Site Generator (SSG), and Server Side Rendering (SSG), and Content
Management System (CMS). Having experience working in an agile
development environment using Agile and Scrum.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      